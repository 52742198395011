"use client";
import { memo, useMemo, useRef } from "react";
import isHandledByNavigation from "../../helpers/isHandledByNavigation";
import { ModalConfig } from "../../types";
import RootModalIndividual from "./components/RootModalIndividual";

const RootModalContainer = ({ config: configProp }: { config: ModalConfig<any> }) => {
	const initialConfig = useRef(configProp);
	const config = useMemo(() => initialConfig.current, []);

	return (
		<>
			{Object.entries(config)
				.filter(([name, modalConfig]) => !isHandledByNavigation(modalConfig.variant))
				.map(([name, modalConfig]) => {
					if (typeof name !== "string") {
						throw new Error("Modal name must be a string");
					}

					return <RootModalIndividual key={name} name={name} config={modalConfig} />;
				})}
		</>
	);
};
export default memo(RootModalContainer);
